import React, { useEffect, useState } from 'react';
import { fetchChallengesByStatus, updateChallengeStatus, updateChallengeProposedDate } from '../../services/ChallengeService';
import './AdminChallengeView.css';
import { ChallengeView } from '../../types/ChallengeView';
import { ResultView } from '../../types/ResultView';
import { ChallengeStatus } from '../../types/Challenge';
import { useNavigate } from 'react-router-dom';
import { translateRankingType } from '../../helpers/translateRankingType';
import { useAuth } from '../../context/AuthContext';

const AdminChallengeView: React.FC = () => {
    const navigate = useNavigate();
    const { userAuth } = useAuth();
    const [challenges, setChallenges] = useState<ChallengeView[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [statusFilter, setStatusFilter] = useState<ChallengeStatus>('accepted');
    const [editableChallenges, setEditableChallenges] = useState<{ [key: number]: { proposedDate?: Date; status?: ChallengeStatus } }>({});

    useEffect(() => {
        const fetchChallenges = async () => {
            setIsLoading(true);
            try {
                const data: ChallengeView[] = await fetchChallengesByStatus(statusFilter);
                const formattedData = data.map(challenge => ({
                    ...challenge,
                    proposedDate: new Date(challenge.proposedDate)
                }));
                setChallenges(formattedData);
            } catch (error) {
                setError('Error fetching challenges');
            } finally {
                setIsLoading(false);
            }
        };

        fetchChallenges();
    }, [statusFilter]);

    const handleFieldChange = (id: number, field: keyof ChallengeView, value: any) => {
        setEditableChallenges((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
    };

    const handleSave = async (id: number) => {
        const updatedFields = editableChallenges[id] || {};
        const userAuthEmail = userAuth?.email || '';
        //Ask for confirmation before updating the challenge
        const confirmation = window.confirm('¿Estás seguro de que deseas actualizar este desafío?');
        if (!confirmation) return;

        try {
            if (updatedFields.proposedDate) {
                await updateChallengeProposedDate(id, updatedFields.proposedDate);
            }
            if (updatedFields.status) {
                await updateChallengeStatus(id, { status: updatedFields.status }, userAuthEmail);
            }

            setChallenges(challenges.map((challenge) =>
                challenge.id === id ? { ...challenge, ...updatedFields } : challenge
            ));
            setEditableChallenges((prev) => {
                const newEditable = { ...prev };
                delete newEditable[id];
                return newEditable;
            });
        } catch (error) {
            setError('Error updating challenge');
        }
    };

    const handleViewDetails = (id: number) => {
        navigate(`/challenges/${id}`);
    };

    const renderResultDetails = (result: ResultView | undefined) => {
        if (!result) return 'N/A';

        const setsDetails = result.sets.map((set, index) => (
            <div key={index}>
                {set.score} {set.tiebreak ? '(Tiebreak)' : ''}
            </div>
        ));

        return (
            <div>
                {setsDetails}
                {result.walkover && <div>Result by W/O</div>}
                {result.abandoned && <div>Match Abandoned</div>}
            </div>
        );
    };

    if (isLoading) return <p>Loading challenges...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="admin-home">
            <section>
                <h1 className="section-title">Administrador</h1>
                <div className="admin-challenge-view">
                    <h2 className="section-title">Desafíos</h2>

                    <div className="status-filter">
                        <label htmlFor="status">Filtrar por estado</label>
                        <select
                            id="status"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value as ChallengeStatus)}
                        >
                            <option value="pending">Pendientes</option>
                            <option value="accepted">Aceptados</option>
                            <option value="rejected">Rechazados</option>
                            <option value="canceled">Cancelados</option>
                            <option value="played">Jugados</option>
                        </select>
                    </div>

                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th>#</th> {/* Add this for numbering */}
                                <th>Id del desafío</th>
                                <th>Desafiante</th>
                                <th>Desafiado</th>
                                <th>Fecha propuesta</th>
                                <th>Estado</th>
                                <th>Tipo de ranking</th>
                                <th>Id del Ganador</th>
                                <th>Resultado</th>
                                <th className='challenge-admin-button-column'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {challenges.map((challenge, index) => (
                                <tr key={challenge.id}>
                                    <td>{index + 1}</td> {/* Numbering each row */}
                                    <td>{challenge.id}</td>
                                    <td>{challenge.challenger.firstName} <span> </span>
                                        {challenge.challenger.lastName} <span> </span>
                                        {challenge.challenger.active ? <></> : <strong /* red color */ style={{color: "red"}}> (I)</strong>}
                                    </td>
                                    <td>{challenge.challenged.firstName}  <span> </span>
                                        {challenge.challenged.lastName} <span> </span>
                                        {challenge.challenged.active ? <></> : <strong /* red color */ style={{color: "red"}}> (I)</strong>}
                                    </td>
                                    <td>
                                        {
                                            editableChallenges[challenge.id]?.proposedDate ?
                                                editableChallenges[challenge.id]?.proposedDate?.toISOString().split('T')[0] :
                                                challenge.proposedDate.toISOString().split('T')[0]
                                        }
                                    </td>

                                    <td>
                                        {challenge.status !== 'played' ? (
                                            <select
                                                value={editableChallenges[challenge.id]?.status || challenge.status}
                                                onChange={(e) => handleFieldChange(challenge.id, 'status', e.target.value as ChallengeStatus)}
                                            >
                                                <option value="-">-</option>
                                                <option value="pending">Pendiente</option>
                                                <option value="canceled">Cancelado</option>
                                            </select>
                                        ) : (
                                            challenge.status
                                        )}
                                    </td>
                                    <td>{translateRankingType(challenge.rankingType)}</td>
                                    <td>{challenge.winnerId !== null ? challenge.winnerId : 'N/A'}</td>
                                    <td>{renderResultDetails(challenge.result)}</td>
                                    <td className='challenge-admin-button-column'>
                                        {challenge.status !== 'played' && (
                                            <button onClick={() => handleSave(challenge.id)} className="btn-save">Guardar</button>
                                        )}
                                        <button onClick={() => handleViewDetails(challenge.id)} className="btn-view-details">Ver detalles</button>
                                        {challenge.status === 'accepted' && (
                                            <a href={`/load-result?challengeId=${challenge.id}`} className="btn-load-result">
                                                Cargar resultado
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

export default AdminChallengeView;
