import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { allRankTypes, RankType } from '../../types/RankType';
import { createChallenge } from '../../services/ChallengeService';
import { Challenge } from '../../types/Challenge';
import { useAuth } from '../../context/AuthContext';
import { getPlayerById, searchPlayersByName } from '../../services/PlayerService';
import './ChallengeForm.css';
import { translateRankingType } from '../../helpers/translateRankingType';
import { Player } from '../../types/Player';
import moment from 'moment';
import 'moment/locale/es';
import { getTimeSlotColor, timeSlots } from '../../helpers/timeSlotHelpers';
import { getCourtAvailability } from '../../services/CourtService';

const ChallengeForm: React.FC = () => {
  const navigate = useNavigate();
  const { userAuth, playerAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const [challengedId, setChallengedId] = useState<number | null>(null);
  const [proposedDate, setProposedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [rankingType, setRankingType] = useState<RankType>('absoluteOrdinalRanking');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [players, setPlayers] = useState<{ id: number; name: string }[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [rankingTypesToBeShown, setRankingTypesToBeShown] = useState<{ id: number; name: string, value: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [loggedInPlayer, setLoggedInPlayer] = useState<Player | null>(null);
  const [courtAvailability, setCourtAvailability] = useState<Record<string, number>>({});
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [timeSlotsToShow, setTimeSlotsToShow] = useState<string[]>(timeSlots);

  // Set the locale for moment to Spanish
  useEffect(() => {
    moment.locale('es');
    setRankingType('absoluteOrdinalRanking');
    //Get rankingType from query params
    const rankingTypeParam = searchParams.get('rankingType');
    if (rankingTypeParam) setRankingType(rankingTypeParam as RankType || 'absoluteOrdinalRanking');
  }, [searchParams]);

  useEffect(() => {
     // Prepare Time Slots
     const now = new Date();
     const currentHour = now.getHours();
     //Filter time slots that are in the future if the date is today
     if (moment(proposedDate).isSame(now, 'day')) {
       const timeSlotsFiltered = timeSlots.filter((time) => parseInt(time.split(':')[0]) > currentHour);
       setTimeSlotsToShow(timeSlotsFiltered);
     } else {
       setTimeSlotsToShow(timeSlots);
     }
  }
  , [proposedDate]);

  useEffect(() => { // Preload challenged player
    const challengedIdParam = searchParams.get('challengedId');
    if (challengedIdParam) {
      const id = parseInt(challengedIdParam);
      if (!isNaN(id)) {
        setChallengedId(id);
        preloadChallengedPlayer(id);
      }
    }
  }, [searchParams]);

  useEffect(() => { // Ranking types dropdown
    if (loggedInPlayer && selectedPlayer) {
      setRankingTypesInDropdown(loggedInPlayer, selectedPlayer);
    } else {
      setRankingTypesToBeShown([{ id: 0, name: 'Ranking absoluto', value: 'absoluteOrdinalRanking' }]);
    }
  }, [loggedInPlayer, selectedPlayer]);

  useEffect(() => { // Fetch player data
    const fetchPlayer = async () => {
      try {
        if (playerAuth && playerAuth.id) {
          const playerLoggedIn = await getPlayerById(playerAuth.id);
          if (playerLoggedIn) {
            setLoggedInPlayer(playerLoggedIn);
          }
        }
      } catch (error) {
        console.error('Error fetching player:', error);
      }
    };

    fetchPlayer();
  }, [playerAuth]);

  const preloadChallengedPlayer = async (id: number) => {
    try {
      const player = await getPlayerById(id);
      if (player) {
        const playerName = `${player.firstName} ${player.lastName}`;
        setSearchQuery(playerName + ' (ID ' + id + ')');
        setSelectedPlayer(player);
      }
    } catch (error) {
      console.error('Error fetching player:', error);
    }
  };

  const fetchCourtAvailability = async (date: string) => {
    try {
      setLoader(true);
      const dateParsed = date.replace(/-/g, '');
      const availability = await getCourtAvailability(dateParsed);
      setCourtAvailability(availability);
      setLoader(false);
    } catch (error) {
      setError('No se pudo obtener la disponibilidad de pistas.');
      setLoader(false);
    }
  };

  useEffect(() => {
    setError(null);
    setSuccessMessage(null);
    if (!proposedDate) return;
    //Date has to be today or in the future
    const yesterdayAt23hs = new Date();
    yesterdayAt23hs.setDate(yesterdayAt23hs.getDate() - 1);
    yesterdayAt23hs.setHours(23, 0, 0, 0);
    if (new Date(proposedDate) < yesterdayAt23hs) {
      setError('La fecha propuesta debe ser hoy o en el futuro.');
      return;
    }
    fetchCourtAvailability(proposedDate);
  }, [proposedDate]);

  // Handle time selection
  const handleTimeClick = (time: string) => {
    setSelectedStartTime((prevTime) => (prevTime === time ? null : time));
  };

  const setRankingTypesInDropdown = (player1: Player, player2: Player) => {
    const player1RankTypes = allRankTypes.filter((type: RankType) => player1[type as keyof Player] !== null);
    const player2RankTypes = allRankTypes.filter((type) => player2[type as keyof Player] !== null);
    const commonRankTypes = player1RankTypes.filter((type) => player2RankTypes.includes(type));
    const definedRankingTypesToBeShown = commonRankTypes.map((type: RankType, i: number) => ({ id: i, name: translateRankingType(type), value: type }));
    setRankingTypesToBeShown(definedRankingTypesToBeShown);
  };

  const handlePlayerSelect = (playerId: number, playerName: string) => {
    setChallengedId(playerId);
    setSearchQuery(playerName + ' (ID ' + playerId + ')');
    setShowDropdown(false);
  };

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      try {
        const results = await searchPlayersByName(query);
        setPlayers(results.map(player => ({ id: player.id, name: `${player.firstName} ${player.lastName}` })));
        setShowDropdown(true);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    } else {
      setPlayers([]);
      setShowDropdown(false);
    }
  };

  const handleRankingSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRankingType(e.target.value as RankType)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!userAuth) {
      setError('No hay ningún usuario conectado.');
      return;
    }

    if (!playerAuth) {
      setError('No hay ningún jugador conectado.');
      return;
    }

    if (playerAuth.id === challengedId) {
      setError('El jugador desafiante y el jugador retado no pueden ser el mismo.');
      return;
    }

    if (challengedId === null) {
      setError('ID del jugador retado es requerido.');
      return;
    }

    const challengedPlayer = await getPlayerById(challengedId);
    if (!challengedPlayer) {
      setError('Jugador retado no encontrado.');
      return;
    }

    if (loggedInPlayer === null || loggedInPlayer === undefined) {
      setError('No se pudo cargar el jugador conectado.');
      return
    }

    if (loggedInPlayer[rankingType] === null || loggedInPlayer[rankingType] === undefined || loggedInPlayer[rankingType] === 0) {
      setError('El jugador logueado no tiene un ranking ordinal válido: ' + rankingType);
      return;
    }

    if (challengedPlayer[rankingType] === null || challengedPlayer[rankingType] === undefined || challengedPlayer[rankingType] === 0) {
      setError('El jugador retado no tiene un ranking ordinal válido: ' + translateRankingType(rankingType));
      return;
    }

    if (proposedDate === null) {
      setError('Fecha y hora propuesta es invalida.');
      return;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (new Date(proposedDate) < today) {
      setError('La fecha y hora propuesta no puede ser anterior a hoy.');
      return;
    }

    if (selectedStartTime === null) {
      setError('Hora de inicio es requerida.');
      return;
    }

    //set proposed date and time
    const proposedDateObj = new Date(proposedDate);
    const proposedTime = selectedStartTime.split(':');
    const hour = parseInt(proposedTime[0]);
    const minutes = parseInt(proposedTime[1]);
    proposedDateObj.setHours(hour, minutes, 0, 0);

    const newChallenge: Partial<Challenge> = {
      challengerId: playerAuth.id,
      challengedId: challengedId,
      proposedDate: proposedDateObj,
      rankingType,
      status: 'pending',
      isOrdinalRanked: true,
    };
    // Confirmation before processing
    const isConfirmed = window.confirm('¿Estás seguro de que deseas crear este desafío?');
    if (!isConfirmed) {
      return;
    }

    try {
      setLoading(true);
      const userEmail = userAuth?.email || '';
      const createdChallenge = await createChallenge(newChallenge, userEmail);
      setSuccessMessage('Desafío creado con éxito.');
      setError(null);

      setTimeout(() => {
        navigate('/challenges/' + createdChallenge.id);
      }, 1000);

    } catch (error: any) {
      setError('Error al crear el desafío. ' + error.response.data.error);
      setSuccessMessage(null);
    } finally {
      setLoading(false);
    }
  };

  //If playerAuth is null show only the error message
  if (!playerAuth) {
    return (
      <>{error && <p className="form-message form-message-error">{error}</p>}</>
    );
  }

  return (
    <div className="form-container">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800">Crear nuevo desafío</h2>
      {error && <p className="text-red-500 mb-4 text-md">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4 text-lg">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        {loader && <p className="form-message">Cargando disponibilidad de pistas</p>}
        {/* Player search input */}
        <label htmlFor="challengedId" className="block text-sm font-medium text-gray-700 mb-2 label-challenge-form">
          Jugador retado
        </label>
        <div className="player-challenge-search">
          <input
            type="text"
            placeholder="Buscar jugador..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="player-challenge-search-input"
            onFocus={() => setShowDropdown(true)}
          />
          {showDropdown && players.length > 0 && (
            <ul className="search-challenge-dropdown">
              {players.map((player) => (
                <li key={player.id} className="dropdown-item">
                  <button
                    type="button"
                    onClick={() => handlePlayerSelect(player.id, player.name)}
                    className="w-full text-left p-2 hover:bg-gray-100"
                  >
                    {player.name} (ID: {player.id})
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>


        {/* Proposed Date */}
        <div className="mb-3">
          <label htmlFor="proposedDate" className="form-label">Fecha propuesta</label>
          <input
            type="date"
            value={proposedDate}
            onChange={(e) => setProposedDate(e.target.value)}
            className="form-input"
          />
        </div>

        {/* Start Times */}
        <div className="mb-3">
          <label className="form-label">Horarios disponibles</label>
          <div className="time-slot-container">
            {timeSlotsToShow.map((time) => {
              const hour = time.split(':')[0];
              const availability = courtAvailability[hour] || 0;
              return (
                <button
                  key={time}
                  type="button"
                  className={`time-slot ${getTimeSlotColor(availability)} ${selectedStartTime === time ? 'selected' : ''
                    }`}
                  onClick={() => handleTimeClick(time)}
                >
                  {time} <strong>({availability})</strong>
                </button>
              );
            })}
          </div>
        </div>


        {/* Ranking type select */}
        <div className="mb-6">
          <label htmlFor="rankingType" className="block text-sm font-medium text-gray-700 mb-2 label-challenge-form">
            Tipo de ranking
          </label>
          <select
            id="rankingType"
            value={rankingType}
            onChange={handleRankingSelect}
            className="w-full rounded-lg border border-gray-300 shadow-sm p-3 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          >
            {/* Generate options using the array rankingTypesToBeShown */}
            {rankingTypesToBeShown.map((type) => (
              <option key={type.id} value={type.value}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="create-button-form bg-blue-600 text-white py-3 px-4 rounded-lg shadow-sm hover:bg-blue-700 transition duration-300 ease-in-out w-full disabled:opacity-50"
          disabled={loading}
        >
          {loading ? 'Creando desafío...' : 'Crear Desafío'}
        </button>
      </form>
    </div>
  );
};

export default ChallengeForm;
