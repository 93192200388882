export const getTimeSlotColor = (availability: number) => {
    if (availability === 0) return 'bg-red-500/70';       // Sin disponibilidad
    if (availability <= 3) return 'bg-orange-400/70';     // Media disponibilidad
    return 'bg-green-500/70';                             // Alta disponibilidad
  };

  // Generate time slots from 8:00 AM to 10:00 PM with 1-hour gaps
  export const timeSlots = Array.from({ length: 15 }, (_, i) => {
    const hour = (i + 8).toString().padStart(2, '0'); // Add leading zero
    const minutes = '00';
    return `${hour}:${minutes}`;
  });