import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SetResultView } from '../../types/ResultView';
import { getMatchByChallengeId, createOrUpdateMatchResult } from '../../services/MatchService';
import './ResultForm.css';
import { MatchView } from '../../types/MatchView';
import { useAuth } from '../../context/AuthContext';

const ResultForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const challengeId = searchParams.get('challengeId');
  const { playerAuth, userAuth } = useAuth();

  const [matchId, setMatchId] = useState<number | null>(null);
  const [player1, setPlayer1] = useState<{ id: number; name: string } | null>(null);
  const [player2, setPlayer2] = useState<{ id: number; name: string } | null>(null);
  const [winnerId, setWinnerId] = useState<number | null>(null);
  const [walkover, setWalkover] = useState<boolean>(false);
  const [abandoned, setAbandoned] = useState<boolean>(false);
  const [sets, setSets] = useState<SetResultView[]>([{ set: 1, score: '', tiebreak: false }]);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); 

  useEffect(() => {
    const fetchMatchDetails = async () => {
      if (!challengeId) return;

      try {
        const matchDetails: MatchView = await getMatchByChallengeId(Number(challengeId));
        if (!matchDetails) {
          setError('No se encontraron detalles del partido.');
          setTimeout(() => navigate('/'), 1000);
          return;
        }
        setMatchId(matchDetails.id);
        setPlayer1({
          id: matchDetails.player1.id,
          name: `${matchDetails.player1.firstName} ${matchDetails.player1.lastName}`,
        });
        setPlayer2({
          id: matchDetails.player2.id,
          name: `${matchDetails.player2.firstName} ${matchDetails.player2.lastName}`,
        });

        // Populate result data if match result exists
        if (matchDetails.result) {
          setWinnerId(matchDetails.winner ? matchDetails.winner.id : null);
          setWalkover(matchDetails.result.abandoned);
          setAbandoned(matchDetails.result.abandoned);
          setSets(matchDetails.result.sets.length > 0 ? matchDetails.result.sets : [{ set: 1, score: '', tiebreak: false }]);
        }

      } catch (error) {
        setError('Error al cargar los detalles del partido.');
      }
    };

    fetchMatchDetails();
  }, [challengeId, navigate, playerAuth, userAuth]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Validación de ganador
    if (!matchId || !winnerId) {
      setError('El ganador es obligatorio.');
      setIsSubmitting(false);
      return;
    }

    // Validación de walkover y abandonado
    if (walkover && abandoned) {
      setError('No se puede marcar un partido como walkover y abandonado al mismo tiempo.');
      setIsSubmitting(false);
      return;
    }

    // Validación de sets si walkover es verdadero
    if (walkover && sets.length > 0) {
      setError('Si se marca como walkover, los sets deben estar vacíos.');
      setIsSubmitting(false);
      return;
    }

    if (sets.some(set => set.score.split('-').length !== 2)) {
      setError('El puntaje de los sets no es válido.');
      setIsSubmitting(false);
      return;
    }

    // Validación de sets
    const setErrorMessages: string[] = [];
    const validSetPattern = /^\d{1,2}-\d{1,2}$/;

    sets.forEach((set, index) => {
      if (!validSetPattern.test(set.score)) {
        setErrorMessages.push(`Formato del set en el set ${index + 1} no es válido.`);
        return;
      }

      const scores = set.score.split('-').map(score => parseInt(score, 10));
      if (scores.some(score => isNaN(score) || score < 0)) {
        setErrorMessages.push(`El puntaje en el set ${index + 1} no puede ser negativo.`);
      }

      if (scores[0] === scores[1]) {
        setErrorMessages.push(`El puntaje en el set ${index + 1} debe tener un ganador. Los puntajes no pueden ser iguales.`);
      }

      if (scores.some(score => score > 10)) {
        setErrorMessages.push(`El puntaje en el set ${index + 1} no debe ser mayor a 10.`);
      }

      if (scores[0] <= scores[1]) {
        setErrorMessages.push(`El primer número del set ${index + 1} debe ser mayor que el segundo número.`);
      }
    });

    const player1Wins = sets.filter(set => set.score.split('-')[0] > set.score.split('-')[1]).length;
    const player2Wins = sets.filter(set => set.score.split('-')[0] < set.score.split('-')[1]).length;
    if (player1Wins === player2Wins && sets.length < 3) {
      setError('El partido debe tener un ganador.');
      setIsSubmitting(false);
      return;
    }

    if (player1Wins === 0 && player2Wins === 0) {
      setError('Debe haber al menos un set ganado por un jugador.');
      setIsSubmitting(false);
      return;
    }

    if (((player1Wins > player2Wins) && winnerId !== player1?.id) || ((player2Wins > player1Wins) && winnerId !== player2?.id)) {
      if (!walkover || !abandoned) {
        setError('El ganador seleccionado no coincide con la carga de sets.');
        setIsSubmitting(false);
        return;
      }
    }

    // Confirmación antes de proceder
    const isConfirmed = window.confirm('¿Estás seguro de que deseas cargar este resultado?');
    if (!isConfirmed) {
      setIsSubmitting(false);
      return;
    } 

    // Enviar resultado
    try {
      setSuccessMessage('Cargando resultado...');
      const userEmail = userAuth?.email || '';
      if ((playerAuth && (playerAuth.id === player1?.id || playerAuth.id === player2?.id)) || userAuth?.role === 'admin') {
        await createOrUpdateMatchResult(matchId, winnerId, sets, walkover, abandoned, userEmail);
        // Notificación de éxito
        window.alert('Resultado cargado con éxito.');
        setSuccessMessage('Resultado cargado con éxito.');
        setError(null);
        setTimeout(() => {
          navigate('/challenges/' + challengeId);
        }, 700);
      } else {
        setError('No tienes permiso para cargar este resultado.');
      }
    } catch (error) {
      setError('Error al cargar el resultado. Por favor, inténtelo de nuevo.');
      setSuccessMessage(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSetScoreChange = (index: number, player1Score: string, player2Score: string) => {
    const newSets = [...sets];
    newSets[index] = {
      ...newSets[index],
      score: `${player1Score.trim()}-${player2Score.trim()}`
    };
    setSets(newSets);
  };

  const handleSetChange = (index: number, field: keyof SetResultView, value: any) => {
    const newSets = [...sets];
    newSets[index] = { ...newSets[index], [field]: value };
    setSets(newSets);
  };

  const addSet = () => {
    if (sets.length < 3) {
      setSets([...sets, { set: sets.length + 1, score: '', tiebreak: false }]);
    }
  };

  const removeSet = (index: number) => {
    if (sets.length > 1) {
      setSets(sets.filter((_, i) => i !== index));
    }
  };

  return (
    <div className="result-form-container">
      <h2 className="cargar-resultado-title text-3xl font-semibold mb-6 text-gray-800">Cargar Resultado</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        {/* ID del Partido - Hidden */}
        <div className="mb-4 hidden">
          <label className="block text-sm font-medium text-gray-700">ID del Partido</label>
          <input
            type="number"
            className="input-field"
            value={matchId || ''}
            readOnly
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Jugador 1</label>
          <p className='player-name'>{player1 ? `${player1.name} (ID: ${player1.id})` : 'Cargando...'}</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Jugador 2</label>
          <p className='player-name'>{player2 ? `${player2.name} (ID: ${player2.id})` : 'Cargando...'}</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Ganador</label>
          <select
            className="input-field"
            value={winnerId || ''}
            onChange={(e) => setWinnerId(Number(e.target.value))}
          >
            <option value="">Seleccionar ganador</option>
            {player1 && <option value={player1.id}>{player1.name}</option>}
            {player2 && <option value={player2.id}>{player2.name}</option>}
          </select>
        </div>

        <div className="mb-4 flex items-center space-x-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">W/O</label>
            <input
              type="checkbox"
              checked={walkover}
              onChange={() => setWalkover(!walkover)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Abandonado</label>
            <input
              type="checkbox"
              checked={abandoned}
              onChange={() => setAbandoned(!abandoned)}
            />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Sets</label>
          {sets.map((set, index) => (
            <div key={index} className="set-main-container flex items-center mb-2">
              <div className='set-container'>
                <input
                  type="number"
                  className="input-field set-input w-1/2"
                  placeholder={`Ej: 6`}
                  value={set.score.split('-')[0] || ''}
                  onChange={(e) => handleSetScoreChange(index, e.target.value, set.score.split('-')[1] || '')}
                />
                <label className="label-set">{player1?.name}</label>
              </div>
              <div className='set-container'>
                <input
                  type="number"
                  className="input-field set-input w-1/2"
                  placeholder={`Ej: 3`}
                  value={set.score.split('-')[1] || ''}
                  onChange={(e) => handleSetScoreChange(index, set.score.split('-')[0] || '', e.target.value)}
                />
                <label className="label-set">{player2?.name}</label>
              </div>
              <div className='tiebreak-container'>
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={set.tiebreak}
                  onChange={(e) => handleSetChange(index, 'tiebreak', e.target.checked)}
                />
                <span>Es tiebreak?</span>
              </div>
              <button type="button" className="btn-remove-set" onClick={() => removeSet(index)}>
                <span className="eliminar-set-button">Eliminar</span>
              </button>
            </div>
          ))}
          <button type="button" className="add-set btn-primary" disabled={isSubmitting} onClick={addSet}>Añadir Set</button>
        </div>

        <button type="submit" className="submit-button btn-submit" disabled={isSubmitting}>{isSubmitting ? 'Cargando...' : 'Cargar Resultado'}</button>
      </form>
    </div>
  );
};

export default ResultForm;
