import axios from 'axios';
import { MatchView } from '../types/MatchView';
import { SetResultView } from '../types/ResultView';
import { MatchStatsView } from '../types/MatchStatsView';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/matches`;

// Get all matches
export const getAllMatches = async (): Promise<MatchView[]> => {
  const response = await axios.get<MatchView[]>(`${API_BASE_URL}`);
  return response.data;
};

// Get a match by ID
export const getMatchById = async (id: number): Promise<MatchView> => {
  const response = await axios.get<MatchView>(`${API_BASE_URL}/${id}`);
  return response.data;
};

// Create a new match
export const createMatch = async (match: Partial<MatchView>): Promise<MatchView> => {
  const response = await axios.post<MatchView>(`${API_BASE_URL}`, match);
  return response.data;
};

// Update an existing match
export const updateMatch = async (id: number, match: Partial<MatchView>): Promise<MatchView> => {
  const response = await axios.put<MatchView>(`${API_BASE_URL}/${id}`, match);
  return response.data;
};

// Delete a match by ID
export const deleteMatch = async (id: number): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/${id}`);
};

// Get matches by date range
export const getMatchesByDateRange = async (from: string, to: string, userId?: number): Promise<MatchView[]> => {
  const queryParams = new URLSearchParams({ startDate: from, endDate: to });
  if (userId) {
    queryParams.append('userId', userId.toString());
  }

  const response = await axios.get<MatchView[]>(`${API_BASE_URL}/date-range?${queryParams.toString()}`);
  return response.data;
};

// Get a match by challenge ID
export const getMatchByChallengeId = async (challengeId: number): Promise<MatchView> => {
  const response = await axios.get<MatchView>(`${API_BASE_URL}/challenge/${challengeId}`);
  return response.data;
};

// Create or update a match result
export const createOrUpdateMatchResult = async (
  matchId: number,
  winnerId: number,
  sets: SetResultView[] = [],
  walkover: boolean = false,
  abandoned: boolean = false,
  updatedBy: string = ''
): Promise<MatchView> => {
  const response = await axios.post<MatchView>(`${API_BASE_URL}/${matchId}/result?user=${updatedBy}`, {
    winnerId,
    resultDetails: { sets, walkover, abandoned },
  });
  return response.data;
};

// Get all match views with player details
export const getAllMatchesView = async (): Promise<MatchView[]> => {
  const response = await axios.get<MatchView[]>(`${API_BASE_URL}/views`);
  return response.data;
};

// Confirm a match
export const confirmMatch = async (matchId: number): Promise<MatchView> => {
  const response = await axios.post<MatchView>(`${API_BASE_URL}/${matchId}/confirm`);
  return response.data;
};

export const getPendingConfirmationMatches = async (): Promise<MatchView[]> => {
  const response = await axios.get<MatchView[]>(`${API_BASE_URL}/pending-confirmation`);
  return response.data;
};

// Fetch player statistics within a date range
export const getPlayerStatsByDateRange = async (playerId: number, from: string, to: string): Promise<MatchStatsView> => {
  const response = await axios.get<any>(`${API_BASE_URL}/playerstats/${playerId}`, {
    params: { from, to }
  });
  return response.data;
};

export const didPlayerHaveWorsePreRankInLastThreeMatches = async (playerId: number): Promise<boolean> => {
  const response = await axios.get<boolean>(`${API_BASE_URL}/player/defended/${playerId}`);
  return response.data;
}

export const getMatchesCountWithoutConfirmation = async (): Promise<number> => {
  try {
    const response = await axios.get<number>(`${API_BASE_URL}/withoutConfirmation`);
    return response.data;
  } catch (error) {
    console.error('Error fetching challenges without confirmation:', error);
    throw error;
  }
};