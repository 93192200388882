import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createOpenChallenge } from '../../services/OpenChallengeService'; // Service method
import { OpenChallenge } from '../../types/OpenChallenge';
import './OpenChallengeForm.css';
import { useAuth } from '../../context/AuthContext';
import { getPlayerById } from '../../services/PlayerService';
import { getCourtAvailability } from '../../services/CourtService';
import { getTimeSlotColor, timeSlots } from '../../helpers/timeSlotHelpers';

const OpenChallengeForm: React.FC = () => {
  const { playerAuth } = useAuth();
  const [proposedDate, setProposedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [startTimes, setStartTimes] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [canCreateOpenChallenge, setCanCreateOpenChallenge] = useState<boolean>(true);
  const [loggedInPlayer, setLoggedInPlayer] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [courtAvailability, setCourtAvailability] = useState<Record<string, number>>({});
  const [loader, setLoader] = useState<boolean>(false);
  const [timeSlotsToShow, setTimeSlotsToShow] = useState<string[]>(timeSlots);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayerData = async () => {
      setCanCreateOpenChallenge(true);
      setError(null);
      setLoader(true);
      // Check for null on playerAuth
      if (!playerAuth || !playerAuth.id) {
        setCanCreateOpenChallenge(false);
        setError('No se pudo obtener el jugador autenticado.');
        setLoader(false);
        return;
      }
      const playerData = await getPlayerById(playerAuth.id);
      setLoggedInPlayer(playerData);
      //player should have absoluteOrdinalRanking and should be greater than 0
      if (!playerData || !playerData.absoluteOrdinalRanking || playerData.absoluteOrdinalRanking <= 0) {
        setCanCreateOpenChallenge(false);
        setError('No se pudo obtener el ranking absoluto del jugador autenticado.');
        setLoader(false);
        return;
      }
      setLoader(false);
    };
    fetchPlayerData();
  }, [playerAuth]);

  useEffect(() => {
       // Prepare Time Slots
       const now = new Date();
       const currentHour = now.getHours();
       //Filter time slots that are in the future if the date is today
        if (proposedDate === now.toISOString().split('T')[0]) {
         const timeSlotsFiltered = timeSlots.filter((time) => parseInt(time.split(':')[0]) > currentHour);
         setTimeSlotsToShow(timeSlotsFiltered);
       } else {
         setTimeSlotsToShow(timeSlots);
       }
    }
    , [proposedDate]);

  const fetchCourtAvailability = async (date: string) => {
    try {
      setLoader(true);
      const dateParsed = date.replace(/-/g, '');
      const availability = await getCourtAvailability(dateParsed);
      setCourtAvailability(availability);
      setLoader(false);
    } catch (error) {
      setError('No se pudo obtener la disponibilidad de pistas.');
    }
  };

  useEffect(() => {
    setError(null);
    setSuccessMessage(null);
    if (!proposedDate) return;
    //Date has to be today or in the future
    const yesterdayAt23hs = new Date();
    yesterdayAt23hs.setDate(yesterdayAt23hs.getDate() - 1);
    yesterdayAt23hs.setHours(23, 0, 0, 0);
    if (new Date(proposedDate) < yesterdayAt23hs) {
      setError('La fecha propuesta debe ser hoy o en el futuro.');
      return;
    }
    fetchCourtAvailability(proposedDate);
  }, [proposedDate]);

  // Handle time selection
  const handleTimeClick = (time: string) => {
    setStartTimes((prevTimes) => {
      if (prevTimes.includes(time)) {
        return prevTimes.filter((t) => t !== time); // Remove time if already picked
      } else {
        return [...prevTimes, time]; // Add time if not picked
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!proposedDate || startTimes.length === 0) {
      setError('Debes elegir una fecha y al menos un horario.');
      return;
    }

    //Date has to be today or in the future
    const yesterdayAt23hs = new Date();
    yesterdayAt23hs.setDate(yesterdayAt23hs.getDate() - 1);
    yesterdayAt23hs.setHours(23, 0, 0, 0);
    if (new Date(proposedDate) < yesterdayAt23hs) {
      setError('La fecha propuesta debe ser hoy o en el futuro.');
      return;
    }

    // Check for null on playerAuth
    if (!playerAuth || !playerAuth.id) {
      setCanCreateOpenChallenge(false);
      setError('No se pudo obtener el jugador autenticado.');
      return;
    }

    const newOpenChallenge: Partial<OpenChallenge> = {
      playerId: playerAuth.id,
      playerRanking: loggedInPlayer.absoluteOrdinalRanking || 0,
      proposedDate: new Date(proposedDate),
      startTimes,
      rankingType: 'absoluteOrdinalRanking',
      status: 'open',
    };

    try {
      await createOpenChallenge(newOpenChallenge);
      setSuccessMessage('Desafío abierto creado con éxito.');
      setTimeout(() => {
        navigate('/');
      }, 800);
    } catch (error: any) {
      console.log(error)
      setError('Error: ' + error.response.data.message);
      setSuccessMessage(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  //If playerAuth is null show only the error message
  if (!playerAuth) {
    return (
      <>{error && <p className="form-message form-message-error">{error}</p>}</>
    );
  }

  return (
    <div className="form-container">
      <h2 className="form-title">Crear nuevo desafío abierto</h2>
      {loader && <p className="form-message">Cargando...</p>}
      {error && <p className="form-message form-message-error">{error}</p>}
      {successMessage && <p className="form-message form-message-success">{successMessage}</p>}

      <form onSubmit={handleSubmit}>
        {/* Proposed Date */}
        <div className="mb-3">
          <label htmlFor="proposedDate" className="form-label">Fecha propuesta</label>
          <input
            type="date"
            value={proposedDate}
            onChange={(e) => setProposedDate(e.target.value)}
            className="form-input"
          />
        </div>

        {/* Start Times */}
        <div className="mb-3">
          <label className="form-label">Horarios disponibles</label>
          <div className="time-slot-container">
            {timeSlotsToShow.map((time) => {  
              const hour = time.split(':')[0];
              const availability = courtAvailability[hour] || 0;
              return (
                <button
                  key={time}
                  type="button"
                  className={`time-slot ${getTimeSlotColor(availability)} ${startTimes.includes(time) ? 'selected' : ''}`}
                  onClick={() => handleTimeClick(time)}
                >
                  {time} <strong>({availability})</strong>
                </button>
              );
            })}
          </div>
        </div>

        {/* Submit Button*/}
        {canCreateOpenChallenge ? (
          // show the button as disabled if the form is submitting
          <button
            type="submit"
            className="form-button"
            disabled={isSubmitting}
          >Crear desafío abierto</button>
        ) : (
          <p className="form-message form-message-error">No se pudo obtener el jugador autenticado.</p>
        )}

        <p className="mt-4 text-sm text-gray-500">
          La cantidad de pistas disponibles para cada horario se muestra entre parentesis <strong>(x)</strong>.
        </p>
        <p className="mt-4 text-sm text-gray-500">
          La aplicación <strong>no realizará la reserva de pistas</strong>. Solamente muestra disponibilidad para esos horarios
        </p>
      </form>
    </div>
  );
};

export default OpenChallengeForm;
